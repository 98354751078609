<template>
    <div style="height: 100%;">
        <a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
            <a-col :span="24" class="mb-24">
                <!-- <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }"> -->
                <div class="lication_page">
                    <div class="draw_head_box">
                        <div class="draw_head_housing">
                            <div class="draw_head_cont">
                                <div class="set_titel_test">
                                    <h3>应用中心</h3>
                                    <!-- <p>浏览器、文档插件，移动端应用</p> -->
                                    <p>客户端、移动端、微信端、福利群应用中心</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <el-scrollbar> -->
                    <div class="draw_body_box">
                        <div class="draw_body_cont">
                            <div class="cation_tit">微信端</div>
                            <div class="cation_chil">微信扫码关注，立即体验wow</div>
                            <div class="applist">
                                <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/gongzhonghao.jpg" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 公众号</p>
                                        <p>扫码关注微信公众号,关注最新福利</p>
                                        <p style="color: #1890ff;">打开微信，扫码立即体验</p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/xiaochengxu.jpg" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">小程序</p>
                                        <p>扫码关注微信小程序,与角色免费聊天</p>
                                        <p style="color: #1890ff;">打开微信，扫码立即体验</p>
                                    </div>
                                </div>
                            </div>
                            <div class="cation_tit">移动端</div>
                            <div class="cation_chil">手机扫码下载APP，随时随地创建完善角色</div>
                            <div class="applist">
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/IOS.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">IOS APP</p>
                                        <p>微信扫码下载wowAPP</p>
                                        <p><a href="https://wanghongxiu.com/ios/" target="_blank"><a-button
                                                    type="primary">前往下载</a-button></a></p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/baidu.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 安卓APP</p>
                                        <p>百度应用市场下载</p>
                                        <p><a href="https://shouji.baidu.com/detail/5000030028?source=appbaidu"
                                                target="_blank"><a-button type="primary">前往下载</a-button></a></p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/pu.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 安卓APP</p>
                                        <p>蒲公英下载</p>
                                        <!-- @click="downloadAPP" -->
                                        <p><a href="https://www.pgyer.com/quelianai" target="_blank"><a-button
                                                    type="primary">前往下载</a-button></a> </p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/wdj.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 安卓APP</p>
                                        <p>豌豆荚下载</p>
                                        <!-- @click="downloadAPP" -->
                                        <p><a href="https://www.wandoujia.com/apps/8380850" target="_blank"><a-button
                                                    type="primary">前往下载</a-button></a> </p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/pp.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 安卓APP</p>
                                        <p>PP助手下载</p>
                                        <!-- @click="downloadAPP" -->
                                        <p><a href="https://wap.pp.cn/app_Bycxhcxyudcxe/?from=singlemessage" target="_blank"><a-button
                                                    type="primary">前往下载</a-button></a> </p>
                                    </div>
                                </div>
                            </div>
                            <div class="cation_tit">电脑客户端</div>
                            <div class="cation_chil">下载桌面软件，使用wow更便捷</div>
                            <div class="applist">
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/win.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">Windows客户端</p>
                                        <p>专为Windows系统(64位)设计的桌面软件</p>
                                        <p style="display: flex;"><a-button @click="downloadBig" type="primary"
                                                style="margin-right: 10px;">本地下载</a-button><a
                                                href="https://pan.baidu.com/s/1WZYd1TLLM65jdAx-CEDOFw"
                                                target="_blank"><a-button type="primary">百度网盘（提取码: api9）</a-button></a></p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/win.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">Windows客户端</p>
                                        <p>专为Windows系统(32位)设计的桌面软件 </p>
                                        <p style="display: flex;"><a-button @click="downloadSmall" type="primary"
                                                style="margin-right: 10px;">本地下载</a-button><a
                                                href="https://pan.baidu.com/s/1eEjpwkgtyRkeaXj2I20ktw"
                                                target="_blank"><a-button type="primary">百度网盘（提取码: qgfu）</a-button></a></p>
                                    </div>
                                </div>

                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/mac.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">Mac客户端</p>
                                        <p>专为Mac系统设计的桌面软件</p>
                                        <p style="display: flex;"><a-button @click="downloadArm" type="primary"
                                                style="margin-right: 10px;">本地下载</a-button><a
                                                href="https://pan.baidu.com/s/15h4PS-SH4vOgPF7zyauLOQ"
                                                target="_blank"><a-button type="primary">百度网盘（提取码: mff3）</a-button></a></p>
                                    </div>
                                </div>
                            </div>


                            <div class="cation_tit">自媒体</div>
                            <div class="cation_chil">关注我们，了解最新活动</div>
                            <div class="applist">
                                <div class="application minin">
                                    <div style=" width: 120px;height: 120px; margin-right: 20px;">
                                        <img src="../assets/imgs/weibo.png" width="120px" height="120px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">微博</p>
                                        <p>扫码关注官方微博</p>
                                        <p><a href="https://weibo.com/u/7779440857" target="_blank"><a-button
                                                    type="primary">前往关注</a-button></a></p>
                                    </div>
                                </div>
                                <!-- <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/weibo.png" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">微博</p>
                                        <p>扫码关注官方微博</p>
                                        <p><a href="https://weibo.com/u/7779440857"
                                                target="_blank"><a-button type="primary">前往关注</a-button></a></p>
                                    </div>
                                </div> -->
                                <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/cpsc.png" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">手册</p>
                                        <p>wow产品手册</p>
                                        <p><a href="https://help.wanghongxiu.com" target="_blank"><a-button
                                                    type="primary">前往学习</a-button></a></p>
                                    </div>
                                </div>
                                <!-- <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/QQ.png" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">QQ群</p>
                                        <p>扫码加入QQ群,领取内部福利</p>
                                        <p style="color: #1890ff;">打开QQ，扫码立即加群</p>
                                    </div>
                                </div> -->
                            </div>
                            <div class="cation_tit">福利群</div>
                            <div class="cation_chil">加群领福利，咨询客服及时响应</div>
                            <div class="applist">
                                <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img :src="img" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob"> 微信群</p>
                                        <p>扫码加入微信群,领取内部福利</p>
                                        <p style="color: #1890ff;">打开微信，扫码立即加群</p>
                                    </div>
                                </div>
                                <div class="application minin">
                                    <div style=" width: 100px;height: 100px; margin-right: 20px;">
                                        <img src="../assets/imgs/QQ.png" width="100px" height="100px" alt="">
                                    </div>
                                    <div>
                                        <p class="blob">QQ群</p>
                                        <p>扫码加入QQ群,领取内部福利</p>
                                        <p style="color: #1890ff;">打开QQ，扫码立即加群</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </el-scrollbar> -->
                </div>
                <!-- </a-card> -->
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { config } from "mathjs"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            img: ""
        }
    },
    watch: {
        tabsId(newValue, oldValue) {
            console.log(newValue, "切换分类")
        }
    },
    computed: {
        ...mapGetters("user", ["userInfo"]),
        ...mapGetters("app", ["config"])

    },
    created() {
        this.img = 'https://quelianai023.oss-accelerate.aliyuncs.com' + this.config.wx_code
    },
    methods: {
        downloadBig() {
            let a = document.createElement("a");
            a.href = "./QueLianAi-win-3.6.0-x64.exe";
            a.download = 'QueLianAi-win-3.6.0-x64.exe';
            a.click();
        },
        downloadSmall() {
            let a = document.createElement("a");
            a.href = "./QueLianAi-win-3.6.0-ia32.exe";
            a.download = 'QueLianAi-win-3.6.0-ia32.exe';
            a.click();
        },
        downloadArm() {
            // window.location.href = "../../public/quelian_x64.exe";
            // let downloadUrl = `${process.env.BASE_URL}/quelian_x64.exe` // 模板下载文件地址
            // let downloadUrl = '/quelian_x64.exe'   // 模板下载文件地址
            // console.log('链接', downloadUrl);
            // window.location.href = downloadUrl

            window.location.href = "./QueLianAi-mac-3.6.0-arm64.dmg"
        }

    }
}
</script>

<style lang="scss" scoped>
.lication_page {
    // height: 100vh;
    // background: #F6F9FD;
    box-sizing: border-box;

    .draw_head_box {
        box-sizing: border-box;
        padding: 0 50px;

        .draw_head_housing {
            padding: 25px 0;
            border-bottom: 1px solid #ebebeb;
            box-sizing: border-box;

            .draw_head_cont {
                margin: 0 auto;
                display: flex;

                &>a {
                    font-size: 18px;
                    color: #409eff;
                    font-weight: bolder;
                }

                .set_titel_test {
                    margin-left: 15px;

                    &>h3 {
                        font-size: 18px;
                        color: #333;
                    }

                    &>p {
                        font-size: 14px;
                        color: #666;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    // .el-scrollbar {
    //     height: calc(100vh - 100px);
    // }

    .draw_body_box {
        padding: 0 65px 0;
        box-sizing: border-box;
        // height: calc(100vh - 99px);

        .draw_body_cont {
            padding-bottom: 50px;

            .cation_tit {
                padding-top: 20px;
                color: #2c3e50;
            }

            .cation_chil {
                margin-top: 10px;
                font-size: 13px;
                color: #888;
            }

            .applist {
                margin-top: 24px;
                width: 95%;
                display: grid;
                grid-template-columns: repeat(2, minmax(200px, 600px));
                border-radius: 4px;
                gap: 2vw 2vw;
                padding-left: 10px;

                .application {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    padding: 20px 30px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    width: 100%;
                    min-height: 160px;
                    background: #fff;
                    border-radius: 4px;
                }

                .application:hover {
                    -webkit-box-shadow: 0 12px 18px 0 rgba(0, 0, 0, .13);
                    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, .13);
                }

                .minin {
                    padding: 40px 30px;
                    display: flex;
                }
            }
        }
    }
}

::v-deep .layout-dashboard .ant-layout-footer {
    display: none !important;
}

.blob {
    font-weight: 700;
    font-size: 16px;
    color: #111;
}
</style>